export enum Icons {
  arrowDown = 'arrow-down',
  plus = 'plus',
  heart = 'heart',
  share = 'share',
  filters = 'filters',
  search = 'search',
  pencil = 'pencil',
  arrowDownThin = 'arrow-down-thin',
  eye = 'eye',
  eyeOpen = 'eye-open',
  categories = 'categories',
  configure = 'configure',
  dataSource = 'data-source',
  donate = 'donate',
  export = 'export',
  general = 'general',
  import = 'import',
  roles = 'roles',
  surveys = 'surveys',
  users = 'users',
  webhook = 'webhook',
  close = 'close',
  dots = 'dots',
  prev = 'prev',
  next = 'next',
  sorting = 'sorting',
  sortUp = 'sort-up',
  sortDown = 'sort-down',
  edit = 'edit',
  collections = 'collections',
  delete = 'delete',
  photo = 'photo',
  key = 'key',
  copy = 'copy',
  searchSmall = 'search-small',
  auth = 'auth',
  logout = 'logout',
  activity = 'activity',
  data = 'data',
  map = 'map',
  help = 'help',
  settings = 'settings',
  download = 'download',
  externalLink = 'external-link',
  arrowRight = 'arrow-right',
  documentCsv = 'document-csv',
  survey = 'survey',
  dragHandle = 'drag-handle',
  twitter = 'twitter',
  facebook = 'facebook',
  twitterFill = 'twitter-fill',
  facebookFill = 'facebook-fill',
  cookies = 'cookies',
  hamburger = 'hamburger',
  account = 'account',
  back = 'back',
  tiles = 'tiles',
  sidebar = 'sidebar',
  refresh = 'refresh',
  editPost = 'edit-post',
  info = 'info',
  tooltip = 'tooltip',
  angleRight = 'angle-right',
  greeting = 'greeting',
  marker = 'marker',
  clapper = 'clapper',
  collectionEdit = 'collection-edit',
  warning = 'warning',
  currentLocation = 'current-location',
  tag = 'tag',
  heartFilled = 'heart-filled',
  coins = 'coins',
  infoCircle = 'info-circle',
  thumbUp = 'thumb-up',
  ellipses = 'ellipses',
  https = 'https',
  report = 'report',
  move = 'move',
  archive = 'archive',
}
